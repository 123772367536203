import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Flims Laax in Graubünden, Switzerland offers some of the most versatile
terrain to ride your mountain bike on. In three days of racing at the
`}<a parentName="p" {...{
        "href": "http://www.trailtrophy.eu"
      }}>{`Trail Trophy Enduro Series`}</a>{` you can discover the
best singletracks of the area in a relaxed atmosphere.`}</p>
    <br />
    <p><strong parentName="p">{`How to win?`}</strong>{` Like our `}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook page`}</a>{`
and comment the post (about the draw). The price is a full 3 day start package
including the start number, timing, lift and shuttles and lunch and dinner at
the race. The winners are drawn on Thursday evening the 18th of July on Facebook.`}</p>
    <br />
    <p>{`Scroll down to get an impression of the area around Flims Laax, or read more
about the destination `}<a parentName="p" {...{
        "href": "/destination/switzerland/flimslaax"
      }}>{`on Trailguide`}</a>{`.`}</p>
    <TrailguidePlugin content="lat=46.8495&lng=9.2305" mdxType="TrailguidePlugin" />
    <p>{`Good luck for the drawing and we are looking forward to see you in Flims Laax!`}</p>
    <Image src="destinations/flimslaaxvorab.jpg" mdxType="Image" />
    <p>{`Vorab Trail - Start on 2600m.a.s.l, you are riding on rocks, smoothly carved
by glaciers. See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3368"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/flimslaaxnagens.jpg" mdxType="Image" />
    <p>{`Höhenweg Nagens - Further down, the nature becomes greener, but the trails are
still technical. See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3371"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/flimslaaxgreenvalley.jpg" mdxType="Image" />
    <p>{`Green Valley - Great trails with lots of roots in the woods.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3369"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/flimslaaxruncatrail.jpg" mdxType="Image" />
    <p>{`Runca Trail - Winds through the green. The famous Runca Trail.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3366"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/flimslaaxruncatrail2.jpg" mdxType="Image" />
    <p>{`Runca Trail - Fast and with lots of berms, tables and wooden elements.
See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3366"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/flimslaaxneverend.jpg" mdxType="Image" />
    <p>{`Never End trail - Also a lot of flow you will find in the berms and jumps of
the Never End trail. See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3368"
      }}>{`here`}</a></p>
    <br />
    <Image src="destinations/flimslaaxneverend2.jpg" mdxType="Image" />
    <p>{`Never End trail - With almost 8km the trail really feels like it would never
end. But who wants that? See the trail `}<a parentName="p" {...{
        "href": "https://trailguide.net/3368"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      